import api from "../../Http/global-vars";
import axios from "axios";
import { toast } from "react-toastify";
import { getData } from "../utils/fetchData";

export const userLogin = async (data) => {
  console.log("data", data);
  return axios
    .post(`${api}auth/login/`, data)
    .then(async (res) => {
      if (res.status === 200) {
        // console.log(res)
        await getData(`me/`, res.data.token)
          .then(async (resp) => {
            // if (resp?.data?.user_type === "beneficiaire") {
            //   toast.error(
            //     "La connexion Adhérent n'est pas encore disponible sur la plateforme web."
            //   );
            // } else {
            await sessionStorage.setItem("userID", resp?.data?.id);
            await sessionStorage.setItem("user", JSON.stringify(resp?.data));
            await sessionStorage.setItem("userType", resp?.data?.user_type);
            await sessionStorage.setItem("authToken", res.data.token);
            //  toast.success('Connexion réussie.')
            await toast.success("Connexion réussie.");
          })
          .catch((e) => {
            return sessionStorage.removeItem("authToken");
          });
      }
    })
    .catch((e) => {
      console.log("ee", e.response, e);
      toast.error(e?.response?.data?.message ||
        "Oupsss! Une erreur est survenue. Veuillez réessayer plus tard."
      );
      // if (e?.response?.data?.non_field_errors) {
      //   toast.error("Email ou mot de passe incorrect !");
      // } else if (e.response?.data?.status === "failure") {
      //   toast.error(e.response?.data?.message);
      // } else {
      //   toast.error(
      //     "Une erreur est survenue lors de la connexion, veuillez vérifier votre connexion internet !"
      //   );
      // }
    });
};

export const userRegister = async (data) => {
  return axios
    .post(`${api}assurance_maladies/`, data)
    .then(async (res) => {
      // console.log("response", res);
      if (res.status === 201) {
        await toast.success("Inscription réussie.");
        if (res.data.user_type === "assureur") {
          setTimeout(() => {
            window.location = "/connexion-assureur";
          }, 3000);
        } else {
          setTimeout(() => {
            window.location = "/connexion";
          }, 3000);
        }
      }
    })
    .catch((e) => {
      console.log(e?.response);
      toast.error(e?.response?.data?.message ||
        "Oupsss! Une erreur est survenue. Veuillez réessayer plus tard."
      );
      // if (
      //   e?.response?.data?.message &&
      //   e?.response?.data?.message === "Cet email est déja utilisé."
      // ) {
      //   toast.error(
      //     "Le mail utilisé est déjà connu de la plateforme, vous pouvez soit vous connecter au compte existant (au besoin, passer par mot de passe oublié) ou créer un nouveau avec un autre compte email.",
      //     {
      //       autoClose: 5000,
      //     }
      //   );
      // } else if (
      //   e?.response?.data?.message &&
      //   e?.response?.data?.message !== "Cet email est déja utilisé."
      // ) {
      //   toast.error(e.response.data.message);
      // } else if (e?.response?.status === 500) {
      //   toast.error(
      //     "Oupss! Une erreur liée au serveur est survenue. Veuillez reéssayer plus tard."
      //   );
      // } else if (e?.response?.status === 400) {
      //   toast.error(
      //     "Oupss! Une erreur liée à la requête est survenue. Veuillez contacter l'administrateur."
      //   );
      // } else {
      //   toast.error(
      //     "Une erreur est survenue lors de l'inscription, veuillez vérifier votre connexion internet !"
      //   );
      // }
    });
};

export const requestPassword = {
  onPostRequest() {
    return `${api}request-password-reset/`;
  },
  onPostReset() {
    return `${api}reset-password/`;
  },
  onChangePassword() {
    return `${api}changepassword/`;
  },
};

export const entreprise = {
  onGetOrPostEntreprise() {
    return `${api}entreprise/`;
  },
  onEditEntreprise(id) {
    return `${api}entreprise/${id}/`;
  },
  onDeleteEntreprise(id) {
    return `${api}entreprise/${id}/`;
  },
};

export const beneficiaire = {
  onGetOrPostBeneficiaire() {
    return `${api}beneficiaire/`;
  },
  onGetBeneficiaire(id) {
    return `${api}beneficiaire/${id}/`;
  },
  onEditBeneficiaire(id) {
    return `${api}beneficiaire/${id}/`;
  },
  onGetBeneficiaireByEntreprise(entrepriseID) {
    return `${api}beneficiairebyemploye/${entrepriseID}`;
  },
  onImportCsv() {
    return `${api}importcsvbenef/`;
  },
  onDeleteBeneficiaire(id) {
    return `${api}beneficiaire/${id}/`;
  },
  onArchiveBeneficiaire(id) {
    return `${api}archivebeneficiaire/${id}/`;
  },
};
export const prestataire = {
  onGetOrPostPrestataire() {
    return `${api}prestataire/`;
  },
  onPutPrestataire(id) {
    return `${api}prestataire/${id}/`;
  },
  onDeletePrestataire(id) {
    return `${api}prestataire/${id}/`;
  },
  onGetPrestationByPrestataire(id) {
    return `${api}prestationbyprestataire/${id}`;
  },
  demandeAgreement() {
    return `${api}prestatairebyentreprise/`;
  },
};

export const typePrestation = {
  onGetOrPostTypePrestation() {
    return `${api}typeprestation/`;
  },
  onPutTypePrestation(id) {
    return `${api}typeprestation/${id}/`;
  },
  onDeleteTypePrestation(id) {
    return `${api}typeprestation/${id}/`;
  },
};

export const assuranceMaladie = {
  onEditAssuranceMaladie(id) {
    return `${api}assurance_maladies/${id}/`;
  },
  onCreateAdminAssuranceMaladie() {
    return `${api}admin_user/`;
  },
  onEditAdminAssuranceMaladie(id) {
    return `${api}admin_user/${id}/`;
  },
  onDeleteAdmin(id) {
    return `${api}admin_user/${id}/`;
  },
  onGetOrPostAssuranceMaladie() {
    return `${api}ipm_by_superadmin/`;
  },
  onDeleteAssuranceMaladie(id) {
    return `${api}assurance_maladies/${id}/`;
  },
};

export const ayantDroit = {
  OnPostAyantDroit() {
    return `${api}ayantdroit/`;
  },
  onPutTypeAyantDroit(id) {
    return `${api}ayantdroit/${id}/`;
  },
  onDeleteAyantDroit(id) {
    return `${api}ayantdroit/${id}/`;
  },
  onGetAyantDroitByBeneficiaire(id) {
    return `${api}ayantdroitbybeneficiaire/${id}/`;
  },
};

export const ficheDeSoin = {
  OnPostFicheDeSoin() {
    return `${api}fichedesoin/`;
  },
  onPutFicheDeSoin(id) {
    return `${api}fichedesoin/${id}/`;
  },
  onDeleteFicheDeSoin(id) {
    return `${api}fichedesoin/${id}/`;
  },
};

export const message = {
  onPostMessage() {
    return `${api}message/`;
  },
};

export const prestation = {
  onCreatePrestation() {
    return `${api}prestation/`;
  },

  onPutPrestation(id) {
    return `${api}prestation/${id}/`;
  },
  onDeletePrestation(id) {
    return `${api}prestation/${id}/`;
  },
};

export const contact = {
  onPostContactMessage() {
    return `${api}contact/`;
  },
};

export const superAdmin = {
  onGetOrPostSuperAdmin() {
    return `${api}super_admin/`;
  },
  onEditSuperAdmin(id) {
    return `${api}super_admin/${id}/`;
  },
  onDeleteSuperAdmin(id) {
    return `${api}super_admin/${id}/`;
  },
};

export const facture = {
  onGetOrPostFacture() {
    return `${api}facture/`;
  },
  onEditFacture(id) {
    return `${api}facture/${id}/`;
  },
  onDeleteFacture(id) {
    return `${api}facture/${id}/`;
  },
  postFacture() {
    return `${api}facture_to_entreprise/`;
  },
  editFacture(id) {
    return `${api}facture_to_entreprise/${id}/`;
  },
  onPutFacture(id) {
    return `${api}facture_to_ipm/${id}/`;
  },
};

export const remboursement = {
  onGetOrPostRemboursement(
    prestataireId,
    beneficiaireId,
    ayant_droitId,
    type_prestationId,
    typedesoinId,
    familledesoinId,
    designation
  ) {
    return `${api}remboursementbyipm/?prestataire=${prestataireId}&beneficiaire=${beneficiaireId}&ayant_droit=${ayant_droitId}&type_prestation=${type_prestationId}&familledesoin=${familledesoinId}&typedesoin=${typedesoinId}&designation=${designation}`;
  },
  onEditRemboursement(id) {
    return `${api}remboursement/${id}/`;
  },
  onDeleteRemboursement(id) {
    return `${api}remboursement/${id}/`;
  },
};

export const exception = {
  onGetOrPostException() {
    return `${api}exception/`;
  },
  onEditException(id) {
    return `${api}exception/${id}/`;
  },
  onDeleteException(id) {
    return `${api}exception/${id}/`;
  },
};

export const service = {
  onGetOrPostService() {
    return `${api}service/`;
  },
  onEditService(id) {
    return `${api}service/${id}/`;
  },
  onDeleteService(id) {
    return `${api}service/${id}/`;
  },
};

export const devis = {
  onGetOrPostDevis() {
    return `${api}devis/`;
  },
  onEditDevis(id) {
    return `${api}devis/${id}/`;
  },
  onDeleteDevis(id) {
    return `${api}devis/${id}/`;
  },
};

export const familleSoin = {
  onGetOrPostFamilleSoin() {
    return `${api}familledesoin/`;
  },
  onEditFamilleSoin(id) {
    return `${api}familledesoin/${id}/`;
  },
  onDeleteFamilleSoin(id) {
    return `${api}familledesoin/${id}/`;
  },
};

export const exclusion = {
  onGetOrPostExclusion() {
    return `${api}exclusion/`;
  },
  onEditExclusion(id) {
    return `${api}exclusion/${id}/`;
  },
  onDeleteExclusion(id) {
    return `${api}exclusion/${id}/`;
  },
};

export const specialite = {
  onGetOrPostSpecialite() {
    return `${api}specialite/`;
  },
  onEditSpecialite(id) {
    return `${api}specialite/${id}/`;
  },
  onDeleteSpecialite(id) {
    return `${api}specialite/${id}/`;
  },
};

export const condition = {
  onGetOrPostCondition() {
    return `${api}conditions/`;
  },
  onPutCondition(id) {
    return `${api}conditions/${id}/`;
  },
  onDeleteCondition(id) {
    return `${api}conditions/${id}/`;
  },
  onGetCgu() {
    return `${api}cgu/`;
  },
  onGetPc() {
    return `${api}ppdp/`;
  },
};
