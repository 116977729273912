import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { DataContext } from "../store/GlobalState";
import Prestataire from "../Services/Prestataire";
import PhoneInput from "react-phone-input-2";
// import 'react-phone-input-2/lib/style.css'
import "react-phone-input-2/lib/bootstrap.css";
import { toast } from "react-toastify";
// import { updateItem } from '../store/Actions'
import * as IconlyPack from "react-iconly";
import { getData, getDataByID } from "../utils/fetchData";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
registerLocale("fr", fr);

export default function EditPrestataireModal() {
  // eslint-disable-next-line
  const { state, dispatch } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(false);

  const { auth, prestataire, list_specialites } = state;

  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    unregister,
    clearErrors,
  } = useForm();

  var [startDate, setStartDate] = React.useState(new Date());
  var [endDate, setEndDate] = React.useState(new Date());
  const [isValid, setIsValid] = React.useState(true);
  const [phone, setPhone] = React.useState("");

  const handleSetPhone = (val) => {
    setPhone(val);
    register("telephone", {
      required: {
        value: true,
        message: "Ce champ est obligatoire.",
      },
      pattern: {
        value: new RegExp(/^((\+)221|0)[1-9](\d{2}){4}$/),
        message: "Veuillez vérifier le format du numéro de téléphone",
      },
    });
    setValue("telephone", "+" + val);
  };

  React.useEffect(() => {
    if (prestataire?.id) {
      setPhone(prestataire?.telephone);
      if (prestataire?.date_debut !== null) {
        setStartDate(new Date(prestataire?.date_debut));
      }
      if (!prestataire?.raison_sociale && prestataire?.prenom) {
        register("prenom", {
          required: "Ce champ est requis.",
        });
        setValue("prenom", prestataire.prenom);
        register("nom", {
          required: "Ce champ est requis.",
        });
        setValue("nom", prestataire.nom);
        unregister("raison_sociale");
      } else {
        register("raison_sociale", {
          required: "Ce champ est requis.",
        });
        setValue("raison_sociale", prestataire.raison_sociale);
        unregister("nom");
        unregister("prenom");
      }
      register("email", {
        required: "Ce champ est requis.",
      });
      setValue("email", prestataire.email);
      register("telephone", {
        required: "Ce champ est requis.",
      });
      setValue("telephone", prestataire.telephone);
      register("adresse", {
        required: "Ce champ est requis.",
      });
      setValue("adresse", prestataire.adresse);
      register("num_contrat");
      setValue("num_contrat", prestataire.num_contrat);
      register("ninea", {
        required: "Ce champ est requis.",
        pattern: {
          value: new RegExp(/^(?=.*\d)(?=.*[a-zA-Z]).{2,}$/),
          message: "Le NINEA doit être de format alpha-numérique.",
        },
        minLength: {
          value: 12,
          message: "Le ninea doit avoir 12 caractères",
        },
      });
      setValue("ninea", prestataire.ninea);
      register("website");
      setValue("website", prestataire.website);
      register("date_debut", {
        required: "Ce champ est requis.",
      });
      setValue("date_debut", prestataire.date_debut);
      register("date_fin", {
        required: "Ce champ est requis.",
      });
      setValue("date_fin", prestataire.date_fin);
      register("specialite", {
        required: "Ce champ est requis.",
      });
      setValue("specialite", prestataire.specialite?.id);
      register("agreement", {
        required: "Ce champ est requis.",
      });
      setValue("agreement", prestataire.agreement);
      register("prestataire_type", {
        required: "Ce champ est requis.",
      });
      setValue("prestataire_type", prestataire.prestataire_type);

      // if (!auth?.user?.is_superuser) {
      //   if (auth?.user?.user_type === 'admin_ipm') {
      //     register('assurance_maladie')
      //     setValue('assurance_maladie', auth?.user?.parent)
      //   } else {
      //     register('assurance_maladie')
      //     setValue('assurance_maladie', auth?.user?.id)
      //   }
      // }

      if (prestataire?.date_fin !== null) {
        setEndDate(new Date(prestataire?.date_fin));
      }
    }
    // console.log(entreprise)
  }, [prestataire?.id]);

  React.useEffect(() => {
    if (errors) {
      setTimeout(() => {
        clearErrors();
      }, 5000);
    }
  }, [errors]);

  const onSubmit = async (data, e) => {
    console.log("data", data);
    setIsLoading(true);

    var prestataireRequest = Prestataire.putPrestataire(
      prestataire.id,
      data,
      requestConfig
    );

    await prestataireRequest
      .then((response) => {
        var prestataireInfos = response;
        // console.log('prestataire', prestataireInfos)
        if (!auth?.user?.is_superuser) {
          if (auth?.user?.user_type === "admin_ipm") {
            getDataByID(
              "prestatairebyassurance",
              auth?.user?.parent,
              auth.token
            )
              .then((res) => {
                dispatch({
                  type: "PRESTATAIRE_BY_IPM",
                  payload: res.results.reverse(),
                });
              })
              .catch((e) => {
                console.log("e", e);
              });
          } else {
            getDataByID("prestatairebyassurance", auth?.user?.id, auth.token)
              .then((res) => {
                dispatch({
                  type: "PRESTATAIRE_BY_IPM",
                  payload: res.results.reverse(),
                });
              })
              .catch((e) => {
                console.log("e", e);
              });
          }
        } else {
          getData("prestataire", auth.token)
            .then((res) => {
              // console.log('data', data)
              dispatch({
                type: "ADD_PRESTATAIRE",
                payload: res.results.sort((a, b) => a?.id - b?.id).reverse(),
              });
            })
            .catch((e) => {
              console.log("e", e);
            });
        }
        setIsLoading(false);
        e.target.reset();
        window.$(".edit-prestataire-modal").modal("hide");
        dispatch({ type: "EDIT_PRESTATAIRE", payload: {} });

        toast.success("Prestataire modifié avec succès.");
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
        toast.error("Oupss! Une erreur est survenue lors de la modification.");
      });
  };

  const handleDateDebut = (date) => {
    setStartDate(date);
    register("date_debut");
    setValue("date_debut", moment(date).format("YYYY-MM-DD"));
  };

  const handleDateFin = (date) => {
    setEndDate(date);
    if (date < startDate) {
      setIsValid(false);
    } else {
      setIsValid(true);
      register("date_fin");
      setValue("date_fin", moment(date).format("YYYY-MM-DD"));
    }
  };

  const handleReset = async () => {
    reset();
    dispatch({ type: "EDIT_PRESTATAIRE", payload: {} });
  };

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5 className="modal-title easypm-modal-title" id="EdingFamilyModal">
            Modifier un prestataire
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => handleReset()}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="eding-family-form-container">
            {prestataire && (
              <form className="crud-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="row crud-row">
                  {prestataire?.raison_sociale && (
                    <div className="col-md-6 crud-col-item">
                      <div className="form-group crud-form-group required">
                        <label htmlFor="name">Nom</label>
                        <input
                          type="text"
                          placeholder="Nom"
                          name="raison_sociale"
                          className="form-control crud-form-control"
                          id="name"
                          defaultValue={prestataire?.raison_sociale || ""}
                          {...register("raison_sociale")}
                        />
                        {errors.raison_sociale && (
                          <div className="alert alert-danger gfa-alert-danger">
                            {" "}
                            {errors.raison_sociale.message}{" "}
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {!prestataire?.raison_sociale && prestataire?.prenom && (
                    <>
                      <div className="col-md-6 crud-col-item">
                        <div className="form-group crud-form-group required">
                          <label htmlFor="prenom">Prénom</label>
                          <input
                            type="text"
                            placeholder="Prénom"
                            name="prenom"
                            className="form-control crud-form-control"
                            id="prenom"
                            defaultValue={
                              prestataire?.prenom ? prestataire?.prenom : ""
                            }
                            {...register("prenom")}
                          />
                          {errors.prenom && (
                            <div className="alert alert-danger gfa-alert-danger">
                              {" "}
                              {errors.prenom.message}{" "}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6 crud-col-item">
                        <div className="form-group crud-form-group required">
                          <label htmlFor="name">Nom</label>
                          <input
                            type="text"
                            placeholder="Nom"
                            name="nom"
                            className="form-control crud-form-control"
                            id="name"
                            defaultValue={
                              prestataire?.nom ? prestataire?.nom : ""
                            }
                            {...register("nom")}
                          />
                          {errors.nom && (
                            <div className="alert alert-danger gfa-alert-danger">
                              {" "}
                              {errors.nom.message}{" "}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="date_debut">Date de début</label>

                      <DatePicker
                        className="form-control crud-form-control"
                        selected={startDate}
                        placeholderText="Sélectionner une date"
                        dateFormat="dd/MM/yyyy"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        locale="fr"
                        onChange={(date) => handleDateDebut(date)}
                      />
                      {errors && errors.date_debut && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {errors.date_debut?.message}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="date_fin">Date de fin</label>
                      {/* <input
                        {...props.register('date_fin')}
                        type="date"
                        name="date_fin"
                        className="form-control crud-form-control"
                        id="date_fin"
                      /> */}
                      <DatePicker
                        className="form-control crud-form-control"
                        selected={endDate}
                        placeholderText="Sélectionner une date"
                        dateFormat="dd/MM/yyyy"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        locale="fr"
                        onChange={(date) => handleDateFin(date)}
                      />
                      {errors && errors.date_fin && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          {errors.date_fin?.message}
                        </div>
                      )}

                      {!isValid && (
                        <div className="alert alert-danger epm-alert-danger closer m-t-10">
                          La date de fin ne peut pas être avant la date de
                          début.
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="first_name">Spécialité</label>
                      <div className="filter-item-container">
                        <select
                          name="specialite"
                          className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                          aria-label="Default select example"
                          {...register("specialite")}
                        >
                          <option hidden value="">
                            Choisir une spécialité
                          </option>
                          {list_specialites?.length &&
                            list_specialites?.map((sp) => (
                              <option key={sp?.id} value={sp?.id}>
                                {sp?.nom}
                              </option>
                            ))}
                        </select>

                        {errors.specialite && (
                          <div className="alert alert-danger gfa-alert-danger">
                            {" "}
                            {errors.specialite.message}{" "}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group required">
                    <label htmlFor="type">Type de prestataire</label>
                    <div className="filter-item-container">
                      <select
                        name="prestataire_type"
                        className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                        aria-label="Default select example"
                        {...register("prestataire_type",{
                          require: "Ce champs est obligatoire."
                        })}
                      >
                        <option  value="">
                          Choisir un type
                        </option>
                        <option value="Établissement santé">Établissement santé</option>
                        <option value="pharmacie">Pharmacie</option>
                        
                      </select>

                      {errors &&
                        errors.prestataire_type && (
                          <div className="alert alert-danger epm-alert-danger closer m-t-10">
                            {errors?.prestataire_type?.message}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="ninea">NINEA</label>
                      <input
                        type="text"
                        placeholder="NINEA"
                        name="ninea"
                        className="form-control crud-form-control"
                        id="ninea"
                        maxLength={12}
                        defaultValue={prestataire?.ninea}
                        {...register("ninea", {
                          required: "Ce champ est requis.",
                          pattern: {
                            value: new RegExp(/^(?=.*\d)(?=.*[a-zA-Z]).{2,}$/),
                            message:
                              "Le NINEA doit être de format alpha-numérique.",
                          },
                          minLength: {
                            value: 12,
                            message: "Le ninea doit avoir 12 caractères",
                          },
                        })}
                      />
                      {errors.ninea && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {" "}
                          {errors.ninea.message}{" "}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="email">Email</label>
                      <input
                        type="text"
                        placeholder="Email"
                        name="email"
                        className="form-control crud-form-control"
                        id="email"
                        defaultValue={prestataire?.email}
                        {...register("email", {
                          pattern: {
                            value: new RegExp(
                              /^(?<user>[a-zA-Z0-9._%+'-]+)@(?<domain>[a-zA-Z0-9.'-]+\.[a-zA-Z]{2,4})$/
                            ),
                            message: "Votre email n'est pas valide.",
                          },
                        })}
                      />
                      {errors.email && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {" "}
                          {errors.email.message}{" "}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="adresse">Adresse</label>
                      <input
                        type="text"
                        placeholder="Adresse"
                        name="adresse"
                        className="form-control crud-form-control"
                        id="adresse"
                        defaultValue={prestataire?.adresse}
                        {...register("adresse")}
                      />
                      {errors.adresse && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {" "}
                          {errors.adresse.message}{" "}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group required">
                      <label htmlFor="telephone">Téléphone</label>
                      <PhoneInput
                        defaultMask={".. ... .. .."}
                        inputClass="form-control crud-form-control"
                        country={"sn"}
                        onlyCountries={["sn"]}
                        inputProps={{
                          name: "telephone",
                          required: true,
                          autoFocus: true,
                        }}
                        disableDropdown={true}
                        enableAreaCodes={true}
                        prefix="+"
                        value={phone}
                        countryCodeEditable={false}
                        onChange={(phone) => handleSetPhone(phone)}
                        inputStyle={{
                          width: "100%",
                          paddingBottom: "22px",
                          borderRadius: "10px",
                        }}
                      />
                      {errors.telephone && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {" "}
                          {errors.telephone.message}{" "}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="numero_contrat">Num. Contrat</label>
                      <input
                        type="text"
                        placeholder="Numéro contrat"
                        name="num_contrat"
                        className="form-control crud-form-control"
                        id="numero_contrat"
                        defaultValue={
                          prestataire.num_contrat ? prestataire.num_contrat : ""
                        }
                        {...register("num_contrat")}
                      />
                      {errors.num_contrat && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {" "}
                          {errors.num_contrat.message}{" "}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    {prestataire?.agreement !== undefined && (
                      <div className="form-group crud-form-group required">
                        <label htmlFor="first_name">Agréement</label>
                        <div className="filter-item-container">
                          <select
                            name="agreement"
                            className="form-control crud-form-control form-select form-select-line-chart ml-md-auto filter-select-item select-crud-form"
                            aria-label="Default select example"
                            defaultValue={prestataire.agreement}
                            {...register("agreement")}
                          >
                            <option hidden value="">
                              Agréement
                            </option>
                            <option value={true}>OUI</option>
                            <option value={false}>NON</option>
                          </select>
                          {errors.agreement && (
                            <div className="alert alert-danger gfa-alert-danger">
                              {" "}
                              {errors.agreement.message}{" "}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <label htmlFor="website">Site web</label>
                      <input
                        type="text"
                        placeholder="Site web"
                        name="website"
                        className="form-control crud-form-control"
                        id="website"
                        defaultValue={prestataire?.website}
                        {...register("website", {
                          pattern: {
                            // eslint-disable-next-line
                            value:
                              /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm,
                            message: "Vous devez renseigner une url valide.",
                          },
                        })}
                      />
                      {errors.website && (
                        <div className="alert alert-danger gfa-alert-danger">
                          {" "}
                          {errors.website.message}{" "}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 offset-md-6 crud-form-action-btn-container">
                    <button
                      className="btn btn-default cancelleb-btn"
                      data-dismiss="modal"
                      onClick={() => handleReset}
                    >
                      Retour
                    </button>
                    {!isLoading ? (
                      <button className="btn btn-primary crud-submit-btn">
                        Enregistrer
                      </button>
                    ) : (
                      <button className="btn btn-primary crud-submit-btn">
                        En cours &nbsp;
                        <i className="fas fa-spin fa-spinner"></i>
                      </button>
                    )}
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
