import React, { useEffect, useState, useContext } from "react";
import {
  BootstrapTable,
  TableHeaderColumn,
  ClearSearchButton,
} from "react-bootstrap-table";
import PrestatairesSkeleton from "./PrestatairesSkeleton";
import * as IconlyPack from "react-iconly";
import { DataContext } from "../../store/GlobalState";
import { NavLink } from "react-router-dom";
import AgreementValidModal from "../../Modals/AgreementValidModal";
import RegenerePwdModal from "../../Modals/RegenerePwdModal";
import moment from "moment";
// import { NavLink } from 'react-router-dom'

var Prestataires = () => {
  const { state, dispatch } = useContext(DataContext);

  const { auth, prestataires, prestatairebyassurance } = state;

  var [isPageLoading, setIsPageLoading] = useState(true);
  // eslint-disable-next-line
  // var [prestataires, setPrestataires] = React.useState(PrestatairesData)

  useEffect(() => {
    if (auth?.token && auth?.user?.id) {
      setIsPageLoading(false);
    } else {
      setIsPageLoading(true);
    }
    // console.log(prestatairebyassurance)
  }, [auth]);

  var handleClearButtonClick = (onClick) => {
    console.log("This is my custom function for ClearSearchButton click event");
    onClick();
  };

  var createCustomClearButton = (onClick) => {
    return (
      <ClearSearchButton
        btnText="Effacer"
        btnContextual="btn-warning"
        className="my-custom-class"
        onClick={(e) => handleClearButtonClick(onClick)}
      />
    );
  };

  const options = {
    clearSearch: true,
    clearSearchBtn: createCustomClearButton,
    noDataText: "Aucune donnée disponible",
  };

  const dateFormater = (cell, row) => {
    return moment(new Date(cell)).format("DD/MM/YYYY");
  };

  const nameFormater = (cell, row) => {
    if (auth?.user?.user_type !== "entreprise") {
      return (
        <div className="nameFormatter" onClick={() => getItem(row)}>
          <span className="name">
            {" "}
            {row?.raison_sociale
              ? row?.raison_sociale
              : row?.prenom + " " + row?.nom}{" "}
          </span>{" "}
          <br />
          {/* {!props.isEntrepriseList && (
          <span className="entreprise"> {row.employeur.raison_sociale} </span>
        )} */}
        </div>
      );
    } else {
      //   return (
      //     <NavLink
      //       to={`/entreprise/prestation-prestataire/${row.id}`}
      //       className="nameFormatter"
      //       onClick={() => getItem(row)}
      //     >
      //       <span className="name">
      //         {' '}
      //         {row?.raison_sociale
      //           ? row?.raison_sociale
      //           : row?.prenom + ' ' + row?.nom}{' '}
      //       </span>{' '}
      //       <br />
      //       {/* {!props.isEntrepriseList && (
      //       <span className="entreprise"> {row.employeur.raison_sociale} </span>
      //     )} */}
      //     </NavLink>
      //   )
      // }
      return row?.raison_sociale || row?.prenom + " " + row?.nom;
    }
  };

  const nameFilter = (cell, row) => {
    return row?.raison_sociale || row?.prenom + " " + row?.nom;
  };

  const specialiteFormat = (cell, row) => {
    return row?.specialite?.nom;
  };

  const agreementFormatter = (cell, row) => {
    if (auth?.user?.user_type !== "entreprise") {
      if (cell === true) {
        return (
          <span
            className="badge badge-success easypm-badge-success linkable"
            data-toggle="modal"
            data-target=".agreement-valid-modal"
            onClick={() => getItem(row)}
          >
            {" "}
            OUI{" "}
          </span>
        );
      } else {
        return (
          <span
            className="badge badge-danger easypm-badge-danger linkable"
            data-toggle="modal"
            data-target=".agreement-valid-modal"
            onClick={() => getItem(row)}
          >
            {" "}
            NON{" "}
          </span>
        );
      }
    } else {
      if (cell === true) {
        return (
          <span className="badge badge-success easypm-badge-success ">
            {" "}
            OUI{" "}
          </span>
        );
      } else {
        return (
          <span className="badge badge-danger easypm-badge-danger"> NON </span>
        );
      }
    }
  };

  const actionFormatter = (cell, row) => {
    return (
      <div className="table-actions-btn-container">
        {auth?.user?.user_type !== "entreprise" && (
          <NavLink
            to={`/ipm/prestation-prestataire/${row.id}`}
            className="action-btn show-btn"
            title="Prestations"
          >
            <IconlyPack.Document
              set="light"
              primaryColor="#C8C8C8"
              className="action-btn-icon action-show-icon"
              // data-toggle="modal"
              // data-target=".show-prestataire-modal"
              onClick={() => getItem(row)}
            />
          </NavLink>
        )}
        <button className="action-btn edit-btn" title="Modification">
          <IconlyPack.Edit
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
            data-toggle="modal"
            data-target=".edit-prestataire-modal"
            onClick={() => getItem(row)}
          />
        </button>
        <button
          className="action-btn edit-btn"
          onClick={() => getItem(row)}
          data-toggle="modal"
          data-target=".regenere-pwd-modal"
          title="Renvoi de mot de passe"
        >
          <IconlyPack.Password
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-edit-icon"
          />
        </button>
        {/* <button className="action-btn delete-btn">
          <IconlyPack.Delete
            set="light"
            primaryColor="#C8C8C8"
            className="action-btn-icon action-delete-icon"
            data-toggle="modal"
            data-target=".delete-prestataire-modal"
            onClick={() => getItem(row)}
          />
        </button> */}
      </div>
    );
  };

  const getItem = (item) => {
    dispatch({
      type: "EDIT_PRESTATAIRE",
      payload: item,
    });
  };

  const isExpandableRow = (row) => {
    if (row.id) return true;
    else return false;
  };

  const expandComponent = (row) => {
    return (
      <div className="easypm-collapse-body">
        <div className="card">
          <div className="card-body">
            <div className="add-family-form-container">
              {row && (
                <div className="crud-form">
                  <div className="row crud-row">
                    <div className="col-md-6 crud-col-item">
                      <div className="form-group crud-form-group">
                        <strong htmlFor="numero_contrat">N°.Contrat: </strong>

                        <span className="crud-form-control">
                          {row?.num_contrat}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 crud-col-item">
                      <div className="form-group crud-form-group">
                        <strong htmlFor="first_name">Agréement: </strong>
                        <span className="crud-form-control">
                          {row?.agreement ? "Oui" : "Non"}
                        </span>
                      </div>
                    </div>
                    {row?.raison_sociale && (
                      <>
                        <div className="col-md-6 crud-col-item">
                          <div className="form-group crud-form-group">
                            <strong htmlFor="name">Nom: </strong>
                            <span className="crud-form-control">
                              {row?.raison_sociale}
                            </span>
                          </div>
                        </div>
                        {/* <div className="col-md-6"></div> */}
                      </>
                    )}

                    {row?.prenom && (
                      <div className="col-md-6 crud-col-item">
                        <div className="form-group crud-form-group">
                          <strong htmlFor="name">Prénom: </strong>
                          <span className="crud-form-control">
                            {row?.prenom}
                          </span>
                        </div>
                      </div>
                    )}

                    {row?.nom && (
                      <div className="col-md-6 crud-col-item">
                        <div className="form-group crud-form-group">
                          <strong htmlFor="name">Nom: </strong>
                          <span className="crud-form-control">{row?.nom}</span>
                        </div>
                      </div>
                    )}

                    <div
                      className={`${
                        row?.specialite?.nom?.length <= 30
                          ? "col-md-6"
                          : "col-md-12"
                      } crud-col-item`}
                    >
                      <div className="form-group crud-form-group">
                        <strong htmlFor="specialite">Spécialité: </strong>
                        <span className="crud-form-control">
                          {row?.specialite?.nom}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 crud-col-item">
                      <div className="form-group crud-form-group">
                        <strong htmlFor="ninea">Type: </strong>
                        <span className="crud-form-control">
                          {row?.prestataire_type}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 crud-col-item">
                      <div className="form-group crud-form-group">
                        <strong htmlFor="ninea">NINEA: </strong>
                        <span className="crud-form-control">{row?.ninea}</span>
                      </div>
                    </div>
                    <div className="col-md-6 crud-col-item">
                      <div className="form-group crud-form-group">
                        <strong htmlFor="adresse">Adresse: </strong>
                        <span className="crud-form-control">
                          {row?.adresse}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 crud-col-item">
                      <div className="form-group crud-form-group">
                        <strong htmlFor="telephone">Téléphone: </strong>
                        <span className="crud-form-control">
                          {row?.telephone}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-6 crud-col-item">
                      <div className="form-group crud-form-group">
                        <strong htmlFor="first_name">Date de début: </strong>
                        <span className="crud-form-control">
                          {row?.date_debut ? row?.date_debut : ""}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-6 crud-col-item">
                      <div className="form-group crud-form-group">
                        <strong htmlFor="first_name">Date de fin: </strong>
                        <span className="crud-form-control">
                          {row?.date_fin ? row?.date_fin : ""}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6 crud-col-item">
                      <div className="form-group crud-form-group">
                        <strong htmlFor="email">Email: </strong>
                        <span className="crud-form-control">{row?.email}</span>
                      </div>
                    </div>

                    <div className="col-md-6 crud-col-item">
                      <div className="form-group crud-form-group">
                        <strong htmlFor="website">Site web: </strong>
                        <span className="crud-form-control">
                          {row?.website}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const optionFilter = {
    true: "OUI",
    false: "NON",
  };

  const optionsFilter = {
    "Établissement santé": "Établissement santé",
    "pharmacie": "Pharmacie",
  };

  return (
    <div className="child-table-container">
      <div className="table-body-container">
        <div className="row easypm-table-row m-t-50 m-b-50">
          <div className="col-md-12 easypm-table-col">
            {isPageLoading && <PrestatairesSkeleton />}
            {!isPageLoading && (
              <div className="table-container">
                <BootstrapTable
                  data={
                    auth?.user?.user_type !== "entreprise"
                      ? prestatairebyassurance
                      : prestataires
                  }
                  striped={true}
                  hover={true}
                  condensed={true}
                  multiColumnSort={2}
                  options={options}
                  pagination
                  search={false}
                  version="4"
                  bordered={false}
                  expandableRow={isExpandableRow}
                  expandComponent={expandComponent}
                >
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    dataField="id"
                    isKey={true}
                    hidden={true}
                  >
                    ID
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    // width="200"
                    filter={{ type: "TextFilter" }}
                    filterValue={nameFilter}
                    dataFormat={(cell, row) => nameFormater(cell, row)}
                    dataField="raison_sociale"
                  >
                    Nom
                  </TableHeaderColumn>

                  {/* <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { borderRadius: "55px", visibility: "hidden" },
                    }}
                    width="120"
                    dataField="ninea"
                  >
                    NINEA
                  </TableHeaderColumn> */}
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { borderRadius: "55px", visibility: "hidden" },
                    }}
                    width="150"
                    dataFormat={dateFormater}
                    dataField="date_fin"
                  >
                    Date de fin
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { borderRadius: "55px", visibility: "hidden" },
                    }}
                    width="150"
                    dataField="telephone"
                  >
                    Téléphone
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { borderRadius: "55px", visibility: "hidden" },
                    }}
                    // width="300"
                    dataFormat={(cell, row) => specialiteFormat(cell, row)}
                    dataField="specialite"
                  >
                    Spécialité
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{ type: "SelectFilter", options: optionFilter }}
                    width="120"
                    dataAlign="center"
                    dataField="agreement"
                    dataFormat={(cell, row) => agreementFormatter(cell, row)}
                  >
                    Agréement
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{ type: "SelectFilter", options: optionsFilter }}
                    // width="120"
                    dataAlign="center"
                    dataField="prestataire_type"
                  >
                    Type
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    thStyle={{
                      fontWeight: 600,
                      fontSize: 16,
                      color: "#1F497D",
                    }}
                    tdStyle={{
                      fontWeight: 500,
                      fontSize: 14,
                      color: "rgba(0, 0, 0, 0.7)",
                    }}
                    filter={{
                      type: "TextFilter",
                      style: { borderRadius: "55px", visibility: "hidden" },
                    }}
                    dataAlign="center"
                    expandable={false}
                    width="120"
                    dataField="id"
                    hidden={auth?.user?.user_type === "entreprise"}
                    dataFormat={(cell, row) => actionFormatter(cell, row)}
                  >
                    Action
                  </TableHeaderColumn>
                </BootstrapTable>
              </div>
            )}
          </div>
          {/* Modal show */}
          <div
            className="modal fade easypm-modal agreement-valid-modal"
            id="CsvModalItem"
            tabIndex="-1"
            data-backdrop="static"
            aria-labelledby="CsvModalItemLabel"
            aria-hidden="true"
          >
            <AgreementValidModal />
          </div>
          {/* Modal regeneration de mot de passe*/}
          <div
            className="modal fade easypm-modal regenere-pwd-modal"
            id="CsvModalItem"
            tabIndex="-1"
            data-backdrop="static"
            aria-labelledby="PwpModalItemLabel"
            aria-hidden="true"
          >
            <RegenerePwdModal />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Prestataires;
