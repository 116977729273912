import React, { useContext, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import Prestataire from "../../../Services/Prestataire";
import { DataContext } from "../../../store/GlobalState";
import { getData, getDataByID } from "../../../utils/fetchData";
import moment from "moment";

function UseCreatePrestataireForm() {
  // eslint-disable-next-line
  const { state, dispatch } = useContext(DataContext);
  let { auth, prestataire } = state;

  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] =
    React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [prestataireData, setPrestataireData] = React.useState();
  const [isFormRequestError, setIsFormRequestError] = React.useState(false);
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${auth?.token}`,
    },
  };

  const validationSchema = useMemo(() =>
    yup.object().shape(
      {
        email: yup
          .string()
          .email("Votre adresse email n'est pas valide")
          .required("L'email est requis"),
        specialite: yup.string().required("La Spécialité est requise"),
        telephone: yup
          .string()
          .required("Le numéro de téléphone est requis")
          .matches(
            /^((\+)221|0)[1-9](\d{2}){4}$/,
            "Veuillez vérifier le format du numéro de téléphone"
          ),
        adresse: yup.string().required("L'adresse est requise"),
        agreement: yup.string().required("L'agréement est requise"),
        ninea: yup.string().when("prenom", {
          is: (val) => !val || val.length === 0,
          then: yup
            .string()
            .matches(
              new RegExp(/^(?=.*\d)(?=.*[a-zA-Z]).{2,}$/),
              "Le NINEA doit être de format alpha-numérique."
            )
            .length(12, "Le ninea doit comporter 12 caractères")
            .required("Le NINEA est requis"),
          otherwise: yup.string(),
        }),
        // ninea: yup
        //   .string()
        //   .matches(
        //     new RegExp(/^(?=.*\d)(?=.*[a-zA-Z]).{2,}$/),
        //     'Le NINEA doit être de format alpha-numérique.',
        //   )
        //   .length(12, 'Le ninea doit comporter 12 caractères')
        //   .required('Le NINEA est requis'),
        date_debut: yup
          .string()
          .required("La Date d'arrivée est requise")
          .default(moment(new Date()).format("YYYY-MM-DD")),
        date_fin: yup
          .string()
          .required("La date de début est requise")
          .default(moment(new Date()).format("YYYY-MM-DD")),
        // assurance_maladie: yup.string().required("L'ipm de début est requis"),
        website: yup.string(),
        prenom: yup.string().when("raison_sociale", {
          is: (val) => !val || val.length === 0,
          then: yup.string().required("Le prénom est requis"),
          otherwise: yup.string(),
        }),
        nom: yup.string().when("raison_sociale", {
          is: (val) => !val || val.length === 0,
          then: yup.string().required("Le nom est requis"),
          otherwise: yup.string(),
        }),
        raison_sociale: yup.string().when("prenom", {
          is: (prenom) => !prenom || prenom.length === 0,
          then: yup.string().required("Le nom est requis."),
          otherwise: yup.string(),
        }),
        prestataire_type: yup.string().required("Le type est obligatoire").nullable()
      },
      ["prenom", "raison_sociale"]
    )
  );

  const validSchema = useMemo(() =>
    yup.object().shape(
      {
        assurance_maladie: yup.string().required("L'ipm est requise"),
      },
      []
    )
  );

  const {
    register,
    handleSubmit,
    errors,
    formState,
    clearErrors,
    setValue,
    reset,
    unregister,
  } = useForm({
    resolver: prestataire?.id
      ? yupResolver(validSchema)
      : yupResolver(validationSchema),
  });

  React.useEffect(() => {
    // console.log(auth?.user?.id)
    if (auth?.user?.user_type !== "entreprise") {
      if (!prestataire?.id && !auth?.user?.is_superuser) {
        if (auth?.user?.user_type === "admin_ipm") {
          register("assurance_maladie");
          setValue("assurance_maladie", auth?.user?.parent);
        } else {
          register("assurance_maladie");
          setValue("assurance_maladie", auth?.user?.id);
        }
      }
    } else {
      register("agreement");
      setValue("agreement", false);
      register("enterprise");
      if (!auth?.user?.admin_type) {
        setValue("enterprise", auth?.user?.id);
      } else {
        setValue("enterprise", auth?.user?.parent);
      }
    }
  }, [register, setValue, auth, prestataire?.id]);

  const onSubmitCreateForm = async (formValues, e) => {
    setIsSuccessfullySubmitted(true);
    if (!prestataire?.id) {
      if (!formValues["raison_sociale"]) {
        formValues["raison_sociale"] = null;
      } else {
        formValues["prenom"] = null;
        formValues["nom"] = null;
      }
      if (auth?.user?.user_type === "admin_ipm") {
        formValues["assurance_maladie"] = auth?.user?.parent;
      } else {
        formValues["assurance_maladie"] = auth?.user?.id;
      }
    } else {
      if (auth?.user?.user_type !== "entreprise") {
        if (auth?.user?.user_type === "admin_ipm") {
          formValues = {
            id: prestataire?.id,
            assurance_maladie: auth?.user?.parent,
          };
        } else {
          formValues = {
            id: prestataire?.id,
            assurance_maladie: auth?.user?.id,
          };
        }
      } else {
        formValues = {
          id: prestataire?.id,
          assurance_maladie: formValues?.assurance_maladie,
        };
      }
    }
    console.log("data", formValues);
    var prestataireRequest = "";
    if (auth?.user?.user_type === "entreprise") {
      prestataireRequest = Prestataire.postDemandeAgreement(
        formValues,
        requestConfig
      );
    } else {
      prestataireRequest = Prestataire.postCreatePrestataire(
        formValues,
        requestConfig
      );
    }

    await prestataireRequest
      .then((response) => {
        var prestataireInfos = response;
        // console.log('prestataire', prestataireInfos)
        setPrestataireData(prestataireInfos);
        console.log(isSuccessfullySubmitted);

        // prestatairebyassurance = [...prestatairebyassurance, prestataireInfos]

        // dispatch({ type: 'PRESTATAIRE_BY_IPM', payload: prestatairebyassurance.sort((a, b) => a.created_at < b.created_at) })

        e.target.reset();
        if (
          auth?.user?.user_type === "ipm" ||
          auth?.user?.user_type === "assurance_sante"
        ) {
          getDataByID("prestatairebyassurance", auth?.user?.id, auth.token)
            .then((res) => {
              dispatch({
                type: "PRESTATAIRE_BY_IPM",
                payload: res.results.reverse(),
              });
            })
            .catch((e) => {
              console.log("e", e);
            });
        }

        if (auth?.user?.user_type === "admin_ipm") {
          getDataByID("prestatairebyassurance", auth?.user?.parent, auth.token)
            .then((res) => {
              dispatch({
                type: "PRESTATAIRE_BY_IPM",
                payload: res.results.reverse(),
              });
            })
            .catch((e) => {
              console.log("e", e);
            });
        }

        if (auth?.user?.is_superuser) {
          getData("prestataire", auth.token)
            .then((res) => {
              // console.log('data', data)
              dispatch({
                type: "ADD_PRESTATAIRE",
                payload: res.results.sort((a, b) => a?.id - b?.id).reverse(),
              });
            })
            .catch((e) => {
              console.log("e", e);
            });
        }
        if (auth?.user?.user_type === "entreprise") {
          if (!auth?.user?.admin_type) {
            getDataByID("prestatairebyentreprise", auth?.user?.id, auth.token)
              .then((res) => {
                // console.log('res pres', res)
                dispatch({
                  type: "ADD_PRESTATAIRE",
                  payload: res.sort((a, b) => a?.id - b?.id).reverse(),
                });
              })
              .catch((e) => {
                console.log("e", e);
              });
          } else {
            getDataByID(
              "prestatairebyentreprise",
              auth?.user?.parent,
              auth.token
            )
              .then((res) => {
                // console.log('res pres', res)
                dispatch({
                  type: "ADD_PRESTATAIRE",
                  payload: res.sort((a, b) => a?.id - b?.id).reverse(),
                });
              })
              .catch((e) => {
                console.log("e", e);
              });
          }
          toast.success("Demande d'agreement envoyée avec succès.");
        } else {
          toast.success("Prestataire ajoutée avec succès.");
        }
        window.$(".add-prestataire-modal").modal("hide");
        dispatch({ type: "EDIT_PRESTATAIRE", payload: {} });
        setIsSuccess(true);
        setIsSuccessfullySubmitted(false);
        reset();
        setTimeout(() => {
          setIsSuccess(false);
        }, 3000);
      })
      .catch((error) => {
        console.log(error?.response);
        setIsFormRequestError(true);
        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
        } else if (
          error?.response?.data?.email &&
          error?.response?.data?.email[0] ===
            "user with this email already exists."
        ) {
          toast.error(
            "Oupsss! L'adresse email est déjà associée à un compte prestataire. Veuillez utiliser une autre adresse email.",
            {
              position: "top-right",
              autoClose: 5000,
            }
          );
        } else if (error?.response?.status === 500) {
          toast.error(
            "Oupss! Une erreur liée au serveur est survenue. Veuillez reéssayer plus tard."
          );
        } else if (
          error?.response?.status === 400 &&
          !error?.response?.data?.email
        ) {
          toast.error(error?.response?.data?.message);
        }
      });
  };

  return {
    register,
    errors,
    formState,
    prestataireData,
    setPrestataireData,
    isSuccessfullySubmitted,
    setIsSuccessfullySubmitted,
    setIsFormRequestError,
    isFormRequestError,
    clearErrors,
    onSubmit: handleSubmit(onSubmitCreateForm),
    setValue,
    reset,
    unregister,
    isSuccess,
  };
}

export default UseCreatePrestataireForm;
