import React, { useContext } from "react";
import { DataContext } from "../store/GlobalState";

export default function ShowPrestataireModal() {
  const { state, dispatch } = useContext(DataContext);

  const { prestataire } = state;

  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <h5
            className="modal-title easypm-modal-title"
            id="ShowingFamilyModal"
          >
            Detail prestataire
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => dispatch({ type: "EDIT_PRESTATAIRE", payload: {} })}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            {prestataire && (
              <div className="crud-form">
                <div className="row crud-row">
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <strong htmlFor="numero_contrat">Num. Contrat: </strong>

                      <span className="crud-form-control">
                        {prestataire?.num_contrat}
                      </span>
                    </div>
                  </div>
                  {prestataire?.raison_sociale && (
                    <div className="col-md-6 crud-col-item">
                      <div className="form-group crud-form-group">
                        <strong htmlFor="name">Nom: </strong>
                        <span className="crud-form-control">
                          {prestataire?.raison_sociale}
                        </span>
                      </div>
                    </div>
                  )}

                  {prestataire?.prenom && (
                    <div className="col-md-6 crud-col-item">
                      <div className="form-group crud-form-group">
                        <strong htmlFor="name">Prénom: </strong>
                        <span className="crud-form-control">
                          {prestataire?.prenom}
                        </span>
                      </div>
                    </div>
                  )}

                  {prestataire?.nom && (
                    <div className="col-md-6 crud-col-item">
                      <div className="form-group crud-form-group">
                        <strong htmlFor="name">Nom: </strong>
                        <span className="crud-form-control">
                          {prestataire?.nom}
                        </span>
                      </div>
                    </div>
                  )}

                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <strong htmlFor="specialite">Spécialité: </strong>
                      <span className="crud-form-control">
                        {prestataire?.specialite?.nom}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <strong htmlFor="specialite">Type: </strong>
                      <span className="crud-form-control">
                        {prestataire?.prestataire_type}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <strong htmlFor="ninea">NINEA: </strong>
                      <span className="crud-form-control">
                        {prestataire?.ninea}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <strong htmlFor="email">Email: </strong>
                      <span className="crud-form-control">
                        {prestataire?.email}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <strong htmlFor="adresse">Adresse: </strong>
                      <span className="crud-form-control">
                        {prestataire?.adresse}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <strong htmlFor="telephone">Téléphone: </strong>
                      <span className="crud-form-control">
                        {prestataire?.telephone}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <strong htmlFor="first_name">Agréement: </strong>
                      <span className="crud-form-control">
                        {prestataire?.agreement ? "Oui" : "Non"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <strong htmlFor="first_name">Date de début: </strong>
                      <span className="crud-form-control">
                        {prestataire?.date_debut ? prestataire?.date_debut : ""}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <strong htmlFor="first_name">Date de fin: </strong>
                      <span className="crud-form-control">
                        {prestataire?.date_fin ? prestataire?.date_fin : ""}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-6 crud-col-item">
                    <div className="form-group crud-form-group">
                      <strong htmlFor="website">Site web: </strong>
                      <span className="crud-form-control">
                        {prestataire?.website}
                      </span>
                    </div>
                  </div>

                  <div
                    className="col-md-6 offset-md-6 crud-form-action-btn-container"
                    style={{ justifyContent: "flex-end" }}
                  >
                    {/* <button
                    className="btn btn-default cancelleb-btn"
                    data-dismiss="modal"
                  >
                    Retour
                  </button> */}

                    <button
                      className="btn btn-danger cancelleb-btn"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() =>
                        dispatch({ type: "EDIT_PRESTATAIRE", payload: {} })
                      }
                    >
                      Retour
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
